import { reactive, toRefs, watch } from "vue";
import { debounce } from "lodash";
import { DataService } from "../config/dataService/dataService";

export default function useHsCodeSearch(hsCodesIDs) {
  const hsCodeSearchState = reactive({
    hsCodeData: [],
    hsCodeValue: [],
    hsCodeFetching: false,
  });

  const getPartnerHsCode = async (hsCodeId) => {
    const query = await DataService.get(
      `api/HSCode/GetByPartenaireId?PartenaireId=${hsCodeId}`
    );
    if (query.succeeded) {
      const data = query.data.map((element) => ({
        label: element.hsCodeName,
        value: element.hsCodeId,
      }));
      hsCodeSearchState.hsCodeData = data;
    }
  };

  let lasthsCodeFetchId = 0;

  const searchHSCode = debounce(async (hscode) => {
    lasthsCodeFetchId += 1;
    const fetchId = lasthsCodeFetchId;
    hsCodeSearchState.hsCodeData = [];
    hsCodeSearchState.hsCodeFetching = true;
    DataService.get(`api/HSCode?OrderBy=name&HSCodeName=${hscode}&PageSize=10`)
      .then((response) => response.data)
      .then((list) => {
        if (fetchId !== lasthsCodeFetchId) {
          return;
        }
        const data = list.map((element) => ({
          label: element.name,
          value: element.id,
        }));
        hsCodeSearchState.hsCodeData = data;
        hsCodeSearchState.hsCodeFetching = false;
      });
  }, 300);

  watch(hsCodesIDs, () => {
    hsCodeSearchState.data = [];
    hsCodeSearchState.hsCodeFetching = false;
  });

  const { hsCodeData } = toRefs(hsCodeSearchState);

  return {
    hsCodeData,
    getPartnerHsCode,
    searchHSCode,
  };
}
