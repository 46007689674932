import { ref } from 'vue';
import { DataService } from '@/config/dataService/dataService';
// TODO chnage composable fname
export function useFileDownload() {
    const downloading = ref(false);

    const downloadFile = async (file) => {
        if (downloading.value) {
            console.warn('Download in progress. Please wait.');
            return;
        }

        try {
            downloading.value = true;
            const response = await DataService.getFile(`/api/File/download?Id=${file.id}`);
            if (!file.extension) {
                file.extension = getFileExtension(file.fileName);
            }

            const isPDF = file.extension?.toLowerCase() === '.pdf';

            if (isPDF) {
                const blob = new Blob([response], { type: "application/pdf" });
                createAndTriggerDownloadLink(blob);
            } else {
                const blob = new Blob([response], { type: "image/png" });
                createAndTriggerDownloadLink(blob);
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        } finally {
            downloading.value = false;
        }
    };
    function getFileExtension(filename) {
        const lastDotPosition = filename.lastIndexOf('.');
        if (lastDotPosition === -1) return '';
        return filename.substring(lastDotPosition);
    }

    const uploadFile = async (fileForm) => {
        try {
            const formData = new FormData();
            formData.append("File", fileForm.file);
            formData.append("ItemId", fileForm.itemId);
            formData.append("ItemParentId", fileForm.itemParentId);
            formData.append("ItemParentType", fileForm.itemParentType);
            formData.append("ItemType", fileForm.itemType);
            formData.append("FileType", fileForm.fileType);
            formData.append("FileName", fileForm.name);

            const response = await DataService.post("api/File/uploadFile", formData);

            if (response.succeeded) {
                return response;
            } else {
                throw new Error("File upload failed.");
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            throw error; // Re-throw the error to handle it elsewhere if needed
        }
    };



    const createAndTriggerDownloadLink = (blob) => {
        var url = URL.createObjectURL(blob);
        window.open(url, "_blank");
    };

    return { downloadFile, downloading, uploadFile, createAndTriggerDownloadLink };
}